<template>
  <div>
    <div
      v-if="loading"
      class="ctk-skeleton-block tw-relative tw-overflow-hidden"
      key="block"
      :class="[`ctk-skeleton-block__level-${level}`]"
      :style="{
        width,
        height,
        ...background ? { 'background-color': `${background} !important`} : {}
      }"
    />
    <slot v-if="!loading" />
  </div>
</template>

<script>
  /**
   * @module component - CtkSkeletonBlock
   * @param {boolean} [loading=false]
   * @param {string} width
   * @param {string} height
   * @param {string} [background=null]
   * @param {number} [level="3"]
   */
  export default {
    name: 'CtkSkeletonBlock',
    props: {
      loading: {
        type: Boolean,
        default: false
      },
      width: {
        type: String,
        required: true
      },
      height: {
        type: String,
        required: true
      },
      background: {
        type: String,
        default: null
      },
      level: {
        type: [Number, String],
        default: 3
      }
    }
  }
</script>

<style lang="scss" scoped>

  $shine-width: 100%;

  .ctk-skeleton-block {
    &:hover {
      cursor: wait;
    }

    &::after {
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      width: $shine-width;
      height: 100%;
      animation: shine-lines 1.6s infinite linear;
      background-repeat: no-repeat;
    }

    &__level-1 {
      $shine-color: #A4AAB3;
      $base-color: rgba($shine-color, 0);

      background-color: $shine-color;

      &::after {
        background-image: linear-gradient(90deg, $base-color 0, rgba(lighten($shine-color, 5%), 0.8) 50%, $base-color 100%);
      }
    }

    &__level-2 {
      $shine-color: #E9E9E9;
      $base-color: rgba($shine-color, 0);

      background-color: $shine-color;

      &::after {
        background-image: linear-gradient(90deg, $base-color 0, rgba(lighten($shine-color, 5%), 0.8) 50%, $base-color 100%);
      }
    }

    &__level-3 {
      $shine-color: #F5F5F5;
      $base-color: rgba($shine-color, 0);

      background-color: $shine-color;

      &::after {
        background-image: linear-gradient(90deg, $base-color 0, rgba(lighten($shine-color, 5%), 0.8) 50%, $base-color 100%);
      }
    }
  }

  @keyframes shine-lines {
    0% {
      left: -($shine-width * 2);
    }

    100% {
      left: 100% + $shine-width;
    }
  }

</style>
