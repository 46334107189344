<template>
  <div
    class="invite-item__actions-container"
  >
    <b-dropdown
      variant="white"
      dropdown
      no-caret
      toggle-class="btn-no-shadow"
      class="ctk-dropdown tw-flex tw-items-center"
      data-test="actions"
    >
      <template #button-content>
        <div class="tw-flex tw-m-auto">
          <ui-material-icon
            class="tw-text-gray-600"
            name="more_vert"
            data-test="icon"
          />
        </div>
      </template>
      <b-dropdown-item
        v-for="action in actionItems"
        :key="action.key"
        @click="action.callback"
        :class="`${memberType}_${action.key}`"
        :data-test="`${memberType}_${action.key}`"
      >
        {{ $t(action.localizationKey) }}
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
  import { computed, defineComponent, toRefs } from '@vue/composition-api'

  export default defineComponent({
    props: {
      memberType: {
        type: String,
        required: true
      },
      actions: {
        type: Array,
        default: () => ([])
      }
    },
    setup (props, { emit }) {
      const { actions, memberType } = toRefs(props)

      const actionItems = computed(() => actions.value.map(key => {
        return {
          key,
          localizationKey: `account.members.buttons.${key}`,
          callback: () => emit(`${memberType.value}-${key}`)
        }
      }))

      return {
        actionItems
      }
    }
  })
</script>
